import React from 'react';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap'; // Import NavDropdown from react-bootstrap

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/dashboard">Child Vaccination</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                    <li className="nav-item">
                            <Link className="nav-link" to="#">Dashboard</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/vaccines">Vaccine List</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/vaccine-brands">Vaccine Brand List</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/age-groups">Age Group List</Link>
                        </li> */}

                        <NavDropdown title="Manage Vaccination" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/vaccines">Manage Vaccines</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/vaccine-brands">Manage Vaccine Brands</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/age-groups">Manage Age Group</NavDropdown.Item>

                        </NavDropdown>

                        {/* Dropdown for Manage Medicine */}
                        <NavDropdown title="Manage Medicine" id="basic-nav-dropdown">
                        
                    <NavDropdown.Item as={Link} to="/manage-medicine/type">Manage Type</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/manage-units">Manage Units</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/manage-frequency">Manage Frequency</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/medicine-times">Manage Medicine Time</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/manage-dosage">Manage Dosage</NavDropdown.Item>                
                    <NavDropdown.Item as={Link} to="/add-medicine">Manage Medicine</NavDropdown.Item>
                </NavDropdown>

                        <li className="nav-item">
                            <Link className="nav-link" to="/logout">Logout</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
