import React from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'; // Use BrowserRouter
import Navbar from './components/Navbar';
import LoginForm from './components/LoginForm';
import VaccineList from './components/VaccineList';
import VaccineForm from './components/VaccineForm';
import VaccineBrandList from './components/VaccineBrandList';
import VaccineBrandForm from './components/VaccineBrandForm';
import AgeGroupList from './components/AgeGroupList';
import AgeGroupForm from './components/AgeGroupForm';
import Logout from './components/Logout';
import MedicineTypeList from './components/MedicineTypeList';
import UnitList from './components/UnitList';
import DosageList from './components/DosageList';
import FrequencyList from './components/FrequencyList';
import MedicineTimeList from './components/MedicineTimeList';
import MedicineForm from './components/MedicineForm';
import 'bootstrap/dist/css/bootstrap.min.css';



const AppContent = () => {
    const location = useLocation();
    

    return (
        <div className="App">
            {location.pathname !== '/' && <Navbar />}
            <div className="container mt-4">
                <Routes>
                    <Route path="/" element={<LoginForm />} />
                    <Route path="/vaccines" element={<VaccineList />} />
                    <Route path="/add-vaccine" element={<VaccineForm />} />
                    <Route path="/vaccine-brands" element={<VaccineBrandList />} />
                    <Route path="/add-vaccine-brand" element={<VaccineBrandForm />} />
                    <Route path="/age-groups" element={<AgeGroupList />} />
                    <Route path="/add-age-group" element={<AgeGroupForm />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/edit-age-group/:id" element={<AgeGroupForm />} />
                    <Route path="/edit-vaccine-brand/:id" element={<VaccineBrandForm />} />
                    <Route path="/edit-vaccine/:id" element={<VaccineForm />} />
                    <Route path="/manage-medicine/type" element={<MedicineTypeList />} />
                    <Route path="/manage-units" element={<UnitList />} />
                    <Route path="/manage-dosage" element={<DosageList />} />
                    <Route path="/manage-frequency" element={<FrequencyList />} />
                    <Route path="/medicine-times" element={<MedicineTimeList />} />
                    <Route path="/add-medicine" element={<MedicineForm />} />

                </Routes>
            </div>
        </div>
    );
};

const App = () => (
    <BrowserRouter>
        <AppContent />
    </BrowserRouter>
);

export default App;
