import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../index';

const AgeGroupForm = () => {
    const [ageGroupName, setAgeGroupName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const fetchAgeGroup = async () => {
                try {
                    const response = await axios.get(`${url}/api/age-groups/${id}`);
                    console.log('Fetched data:', response.data);
                    setAgeGroupName(response.data.data.AgeGroupName);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching age group:', error);
                    setError('Failed to load age group data.');
                    setLoading(false);
                }
            };
            fetchAgeGroup();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await axios.put(`${url}/api/age-groups/${id}`, { AgeGroupName: ageGroupName });
                alert('Age group updated successfully');
                navigate('/age-groups');
            } else {
                await axios.post(`${url}/api/age-groups`, { AgeGroupName: ageGroupName });
                alert('Age group added successfully');
                navigate('/age-groups');
            }
        } catch (error) {
            console.error('Error saving age group:', error);
            setError('Failed to save age group. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <h2>{id ? 'Edit Age Group' : 'Add New Age Group'}</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Age Group Name</label>
                    <input
                        type="text"
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        value={ageGroupName}
                        onChange={(e) => setAgeGroupName(e.target.value)}
                        required
                    />
                    {error && <div className="invalid-feedback">Please enter a valid age group name.</div>}
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    {id ? 'Update Age Group' : 'Add Age Group'}
                </button>
            </form>
        </div>
    );
};

export default AgeGroupForm;
