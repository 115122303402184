import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../index';

const AgeGroupList = () => {
    const [ageGroups, setAgeGroups] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAgeGroups = async () => {
            try {
                const response = await axios.get(`${url}/api/age-groups`);
                console.log('Fetched age groups:', response.data);
    
                // Access the array inside the "data" key
                if (response.data.success && Array.isArray(response.data.data)) {
                    setAgeGroups(response.data.data);
                } else {
                    console.error('Unexpected data format:', response.data);
                    setAgeGroups([]);  // Default to an empty array
                    setError('Unexpected data format received.');
                }
            } catch (error) {
                console.error('Error fetching age groups:', error);
                setError('Failed to load age groups.');
            }
        };
        fetchAgeGroups();
    }, []);
    
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this age group?')) {
            try {
                await axios.delete(`${url}/api/age-groups/${id}`);
                setAgeGroups(ageGroups.filter(group => group.ID !== id));
            } catch (error) {
                console.error('Error deleting age group:', error);
                setError('Failed to delete age group.');
            }
        }
    };

    const handleEdit = (id) => {
        navigate(`/edit-age-group/${id}`);
    };

    return (
        <div className="container">
            <h2>Age Group List</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <Link to="/add-age-group" className="btn btn-primary mb-3">Add New Age Group</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Age Group Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ageGroups.length > 0 ? (
                        ageGroups.map(group => (
                            <tr key={group.ID}>
                                <td>{group.AgeGroupName}</td>
                                <td>
                                    <button 
                                        onClick={() => handleEdit(group.ID)} 
                                        className="btn btn-warning btn-sm me-2"
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={() => handleDelete(group.ID)} 
                                        className="btn btn-danger btn-sm"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">No age groups available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AgeGroupList;
