import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Select, Input, Space, Button } from "antd";
import { url } from '../index';

const VaccineForm = () => {
  const [vaccineBrands, setVaccineBrands] = useState([]);
  const [ageGroups, setAgeGroups] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [currentVaccine, setCurrentVaccine] = useState(null);
  const [form] = Form.useForm(); // Using Ant Design Form instance

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const brandResponse = await axios.get(`${url}/api/vaccine-brands`);
        setVaccineBrands(brandResponse.data.data);

        const ageGroupResponse = await axios.get(`${url}/api/age-groups`);
        setAgeGroups(ageGroupResponse.data.data);

        const vaccineResponse = await axios.get(`${url}/api/vaccines`);
        setVaccines(vaccineResponse.data);

        if (id) {
          const vaccineResponse = await axios.get(`${url}/api/vaccines/${id}`);
          const vaccineData = vaccineResponse.data[0];
          console.log("Vaccine Data", vaccineData);
          setCurrentVaccine(vaccineData);
          // Set form values when data is fetched
          form.setFieldsValue({
            vaccine_name: vaccineData.VaccineName || '',
            vaccine_brand: transformToArray(vaccineData.VaccineBrand),
            days_after_birth: vaccineData.DaysAfterBirth || '',
            age_group: vaccineData.AgeGroup || '',
            dependent_on: transformToArray(vaccineData.DependentVaccineIDs),
            dependent_days: vaccineData.DependentDays || '',
          });
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [id, form]); // Added form to dependency array

  const handleSubmit = async (values) => {
    const payload = {
      VaccineName: values.vaccine_name,
      VaccineBrandIDs: values.vaccine_brand.join(','), // Convert to a comma-separated string
      DaysAfterBirth: values.days_after_birth,
      AgeGroupID: values.age_group,
      DependentOn: values.dependent_on?.join(','), // Convert to a comma-separated string
      DependentDays: values.dependent_days,
    };

    try {
      if (id) {
         await axios.put(`${url}/api/vaccines/${id}`, payload);
        alert("Vaccine updated successfully");
        navigate("/vaccines");
      } else {
         await axios.post(`${url}/api/vaccines`, payload);
        alert("Vaccine added successfully");
        navigate("/vaccines");
      }
    } catch (error) {
      console.error("Error saving vaccine", error);
    }
  };

  // Helper function to transform comma-separated string into an array
  const transformToArray = (value) => value ? value.split(',').map(Number) : [];

  // console.log("Form Initial Values:", {
  //   vaccine_name: currentVaccine?.VaccineName || '',
  //   vaccine_brand: transformToArray(currentVaccine?.VaccineBrand),
  //   days_after_birth: currentVaccine?.DaysAfterBirth || '',
  //   age_group: currentVaccine?.AgeGroup || '',
  //   dependent_on: transformToArray(currentVaccine?.DependentOn),
  //   dependent_days: currentVaccine?.DependentDays || '',
  // });

  return (
    <div className="container">
      <h2>{id ? "Edit Vaccine" : "Add New Vaccine"}</h2>
      <Form
        form={form} // Attach form instance
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Form.Item
            label="Vaccine Name"
            name="vaccine_name"
            rules={[{ required: true, message: "Please input Vaccine Name!" }]}
          >
            <Input type="text" size="large" />
          </Form.Item>
          <Form.Item
            label="Vaccine Brand"
            name="vaccine_brand"
            rules={[{ required: true, message: "Please select Vaccine Brands!" }]}
          >
            <Select
              mode="multiple"
              options={vaccineBrands.map((brand) => ({
                value: brand.ID,
                label: brand.BrandName,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Days After Birth"
            name="days_after_birth"
            rules={[{ required: true, message: "Please input Days After Birth!" }]}
          >
            <Input type="number" size="large" />
          </Form.Item>
          <Form.Item
            label="Age Group"
            name="age_group"
            rules={[{ required: true, message: "Please select Age Group!" }]}
          >
            <Select
              options={ageGroups.map((group) => ({
                value: group.ID,
                label: group.AgeGroupName,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Dependent On"
            name="dependent_on"
          >
            <Select
              mode="multiple"
              options={vaccines.map((vaccine) => ({
                value: vaccine.ID,
                label: vaccine.VaccineName,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Dependent Days"
            name="dependent_days"
          >
            <Input type="number" size="large" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {id ? "Update Vaccine" : "Add Vaccine"}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};

export default VaccineForm;
