import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, Select, Table, Pagination, message, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { url } from '../index';
import './MedicineForm.css'; // Import CSS for styling

const MedicineForm = () => {
    const [types, setTypes] = useState([]);
    const [units, setUnits] = useState([]);
    const [frequencies, setFrequencies] = useState([]);
    const [dosages, setDosages] = useState([]);
    const [medicines, setMedicines] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(5);
    const [totalMedicines, setTotalMedicines] = useState(0);
    const [editingMedicine, setEditingMedicine] = useState(null);

    const [form] = Form.useForm();
    const defaultStrength = '0';  // Example default value for Strength
    const defaultUnitID = 1;      // Example default value for UnitID (assuming ID = 1 exists in Units)

    // Utility to process BigInt fields and convert them to string
    const processBigInt = (data) => {
        return JSON.parse(JSON.stringify(data, (key, value) =>
            typeof value === 'bigint' ? value.toString() : value
        ));
    };

    // Fetch dropdown data and medicines on component mount and page change
    useEffect(() => {
        fetchDropdownData();
        fetchMedicines(currentPage, pageSize);
    }, [currentPage]);

    // Fetch dropdown data for Medicine Type, Unit, Frequency, and Dosage
    const fetchDropdownData = async () => {
        try {
            const [typesRes, unitsRes, frequenciesRes, dosagesRes] = await Promise.all([
                axios.get(`${url}/api/medicine-types`),
                axios.get(`${url}/api/units`),
                axios.get(`${url}/api/frequencies`),
                axios.get(`${url}/api/dosages`)
            ]);

            setTypes(Array.isArray(typesRes.data.data) ? typesRes.data.data : []); // Ensure types is an array
            setUnits(Array.isArray(unitsRes.data.data) ? unitsRes.data.data : []); // Ensure units is an array
            setFrequencies(Array.isArray(frequenciesRes.data.data) ? frequenciesRes.data.data : []); // Ensure frequencies is an array
            setDosages(Array.isArray(dosagesRes.data.data) ? dosagesRes.data.data : []); // Ensure dosages is an array
        } catch (error) {
            message.error('Error fetching dropdown data');
        }
    };

    // Fetch medicines with pagination and handle BigInt serialization
    const fetchMedicines = async (page, pageSize) => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}/api/medicines?page=${page}&pageSize=${pageSize}`);
            const processedData = processBigInt(response.data.data); // Convert BigInt fields to string
            setMedicines(processedData);
            setTotalMedicines(response.data.total); // Ensure total count is set correctly
        } catch (error) {
            console.error('Error fetching medicines:', error);
            message.error('Error fetching medicines');
        } finally {
            setLoading(false);
        }
    };

    // Handle form submission for adding/updating a medicine
    const handleSubmit = async (values) => {
        try {
            const compositionData = values.Composition.map(comp => {
                const match = comp.Strength.match(/(\d+)/); // Extract numeric part from strength
                const numericStrength = match.input; // Get number part of the strength
                return {
                    MedicineName: comp.MedicineName,
                    Strength: numericStrength,
                    UnitID: comp.UnitID
                };
            });

            const updatedValues = { ...values, Composition: compositionData, Strength: defaultStrength, UnitID: defaultUnitID };

            if (editingMedicine) {
                // Update existing medicine
                await axios.put(`${url}/api/medicines/${editingMedicine.ID}`, updatedValues);
                message.success('Medicine updated successfully');
            } else {
                // Add new medicine
                const response = await axios.post(`${url}/api/medicines`, updatedValues);
                setMedicines(prev => [response.data, ...prev]);
                message.success('Medicine added successfully');
            }

            form.resetFields();
            setEditingMedicine(null);
            fetchMedicines(currentPage, pageSize);
        } catch (error) {
            message.error('Error saving medicine');
        }
    };

    // Handle editing a medicine
    const handleEdit = (record) => {
        const formattedComposition = record.FullComposition
            ? record.FullComposition.split(' + ').map(comp => {
                  const match = comp.match(/(\D+)(\d+.*)/); // Matches text and numeric part
                  console.log(match, "numericStrength")
                  return match
                      ? { MedicineName: match[1].trim(), Strength: match[2].split(' ')[0], UnitID: record.UnitID } // Replace non-numeric parts in Strength
                      : { MedicineName: '', Strength: '', UnitID: null };
              })
            : [];

        setEditingMedicine(record);
        form.setFieldsValue({
            TypeID: record.TypeID,
            Name: record.Name,
            Strength: record.Strength.replace(/[^\d]/g, ''), // Replace non-numeric parts in the main strength
            UnitID: record.UnitID,
            FrequencyID: record.FrequencyID,
            DosageID: record.DosageID,
            Composition: formattedComposition,
            NumberOfDays: record.NumberOfDays
        });
    };

    // Handle deleting a medicine
    const handleDelete = async (id) => {
        try {
            await axios.delete(`${url}/api/medicines/${id}`);
            message.success('Medicine deleted successfully');
            fetchMedicines(currentPage, pageSize);
        } catch (error) {
            message.error('Error deleting medicine');
        }
    };

    // Table columns definition
    const columns = [
        {
            title: 'S.No',
            dataIndex: 'S.No',
            key: 'sno',
            // render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, // Calculate serial number based on pagination
        },
        { title: 'Type', dataIndex: 'TypeName', key: 'TypeName' },
        { title: 'Name', dataIndex: 'Name', key: 'Name' },
        { title: 'Composition + Strength + Unit', dataIndex: 'FullComposition', key: 'FullComposition' },
        { title: 'Dosage & Time', dataIndex: 'DosagePatternWithTime', key: 'DosagePatternWithTime' },
        { title: 'Frequency', dataIndex: 'FrequencyName', key: 'FrequencyName' },
        { title: 'Number of Days', dataIndex: 'NumberOfDays', key: 'NumberOfDays' },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Button type="link" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="link" danger onClick={() => handleDelete(record.ID)}>Delete</Button>
                </Space>
            )
        }
    ];

    const handlePageChange = (page) => setCurrentPage(page);

    return (
        <div className="medicine-form-container">
            <h1>Medicine Form</h1>
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{ NumberOfDays: 5 }}>
                <Form.Item name="TypeID" label="Type of Medicine" rules={[{ required: true }]}>
                    <Select placeholder="Select Medicine Type" allowClear>
                        {types.map(type => (
                            <Select.Option key={type.Id} value={type.Id}>{type.Name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="Name" label="Name of Medicine" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>

                <Form.Item name="Strength" label="Main Strength of Medicine" initialValue={defaultStrength} style={{ display: 'none' }}>
                    <Input placeholder="Enter main strength" />
                </Form.Item>

                <Form.Item name="UnitID" label="Main Unit of Medicine" initialValue={defaultUnitID} style={{ display: 'none' }}>
                    <Select placeholder="Select main unit" allowClear>
                        {units.map(unit => (
                            <Select.Option key={unit.Id} value={unit.Id}>{unit.Name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.List name="Composition">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item {...restField} name={[name, 'MedicineName']} rules={[{ required: true, message: 'Enter medicine name' }]}>
                                        <Input placeholder="Medicine Name" />
                                    </Form.Item>

                                    <Form.Item {...restField} name={[name, 'Strength']} rules={[{ required: true, message: 'Enter strength' }]}>
                                        <Input placeholder="Strength" />
                                    </Form.Item>

                                    <Form.Item {...restField} name={[name, 'UnitID']} rules={[{ required: true, message: 'Select unit' }]}>
                                        <Select placeholder="Select Unit">
                                            {units.map(unit => (
                                                <Select.Option key={unit.Id} value={unit.Id}>{unit.Name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add Composition
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item name="DosageID" label="Dosage" rules={[{ required: true }]}>
                    <Select placeholder="Select Dosage" allowClear>
                        {dosages.map(dosage => (
                            <Select.Option key={dosage.ID} value={dosage.ID}>
                                {`${dosage.DosagePattern} (${dosage.MedicineTimeName})`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="FrequencyID" label="Frequency" rules={[{ required: true }]}>
                    <Select placeholder="Select Frequency" allowClear>
                        {frequencies.map(freq => (
                            <Select.Option key={freq.ID} value={freq.ID}>{freq.Name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="NumberOfDays" label="Number of Days" rules={[{ required: true }]}>
                    <Input type="number" min={1} placeholder="Enter number of days" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {editingMedicine ? 'Update Medicine' : 'Add Medicine'}
                    </Button>
                </Form.Item>
            </Form>

            <Table columns={columns} dataSource={medicines} rowKey="ID" loading={loading} pagination={false} />

            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalMedicines}
                onChange={handlePageChange}
                style={{ marginTop: '20px', textAlign: 'center' }}
            />
        </div>
    );
};

export default MedicineForm;
