import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { url } from '../index';

const VaccineBrandForm = () => {
    const [brandName, setBrandName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            const fetchVaccineBrand = async () => {
                try {
                    const response = await axios.get(`${url}/api/vaccine-brands/${id}`);
                    console.log('Fetched data:', response.data);
                    setBrandName(response.data.data.BrandName);
                    setLoading(false);
                } catch (error) {
                    console.error('Error fetching vaccine brand:', error);
                    setError('Failed to load vaccine brand data.');
                    setLoading(false);
                }
            };
            fetchVaccineBrand();
        } else {
            setLoading(false);
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (id) {
                await axios.put(`${url}/api/vaccine-brands/${id}`, { BrandName: brandName });
                alert('Vaccine brand updated successfully');
                navigate('/vaccine-brands');
            } else {
                await axios.post(`${url}/api/vaccine-brands`, { BrandName: brandName });
                alert('Vaccine brand added successfully');
                navigate('/vaccine-brands');
            }
        } catch (error) {
            console.error('Error saving vaccine brand:', error);
            setError('Failed to save vaccine brand. Please try again.');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <h2>{id ? 'Edit Vaccine Brand' : 'Add New Vaccine Brand'}</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Brand Name</label>
                    <input
                        type="text"
                        className={`form-control ${error ? 'is-invalid' : ''}`}
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                        required
                    />
                    {error && <div className="invalid-feedback">Please enter a valid brand name.</div>}
                </div>
                <button type="submit" className="btn btn-primary mt-3">
                    {id ? 'Update Vaccine Brand' : 'Add Vaccine Brand'}
                </button>
            </form>
        </div>
    );
};

export default VaccineBrandForm;
