// src/components/Logout.js

import { useEffect } from 'react'; // Import only what you need
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Perform logout logic here (e.g., clearing tokens, session storage)
        // After logout, redirect to login page
        navigate('/');
    }, [navigate]);

    return null; // No need to return any JSX
};

export default Logout;
