import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { url } from '../index';

const VaccineBrandList = () => {
    const [vaccineBrands, setVaccineBrands] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVaccineBrands = async () => {
            try {
                const response = await axios.get(`${url}/api/vaccine-brands`);
                console.log('Fetched vaccine brands:', response.data);

                if (response.data.success && Array.isArray(response.data.data)) {
                    setVaccineBrands(response.data.data);
                } else {
                    setError('Unexpected data format received.');
                }
            } catch (error) {
                console.error('Error fetching vaccine brands:', error);
                setError('Failed to fetch vaccine brands.');
            } finally {
                setLoading(false);
            }
        };
        fetchVaccineBrands();
    }, []);

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this vaccine brand?')) {
            try {
                await axios.delete(`${url}/api/vaccine-brands/${id}`);
                setVaccineBrands(vaccineBrands.filter(brand => brand.ID !== id));
            } catch (error) {
                console.error('Error deleting vaccine brand:', error);
                setError('Failed to delete vaccine brand.');
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container">
            <h2>Vaccine Brand List</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <Link to="/add-vaccine-brand" className="btn btn-primary mb-3">Add New Vaccine Brand</Link>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Brand Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {vaccineBrands.length > 0 ? (
                        vaccineBrands.map(brand => (
                            <tr key={brand.ID}>
                                <td>{brand.BrandName}</td>
                                <td>
                                    <Link to={`/edit-vaccine-brand/${brand.ID}`} className="btn btn-warning btn-sm mr-2">Edit</Link>
                                    <button onClick={() => handleDelete(brand.ID)} className="btn btn-danger btn-sm">Delete</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">No vaccine brands available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default VaccineBrandList;
