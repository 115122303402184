import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Form, Input, notification, message } from 'antd';
import { url } from '../index';

const MedicineTypeList = () => {
    const [medicineTypes, setMedicineTypes] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentType, setCurrentType] = useState(null);
    const [loading, setLoading] = useState(false); // Track loading state
    const [form] = Form.useForm();

    useEffect(() => {
        fetchMedicineTypes();
    }, []);

    // Fetch Medicine Types
    const fetchMedicineTypes = async () => {
        try {
            // console.log("working on refresh")
            setLoading(true); // Set loading before fetching data
            // setMedicineTypes([]);
            const response = await axios.get(`${url}/api/medicine-types`);
            console.log("responses 😊😊😊", response.data.data)
            setMedicineTypes(response.data.data ? [...response.data.data] : []); // Ensure data is an array

        } catch (error) {
            console.error('Error fetching medicine types:', error);
            message.error('Failed to fetch medicine types');
        } finally {
            setLoading(false); // Stop loading after data is fetched
        }
    };

    // Open modal for adding a new type
    const handleAdd = () => {
        form.resetFields();
        setCurrentType(null);
        setIsModalVisible(true);
    };

    // Open modal for editing a type
    const handleEdit = (medicineType) => {
        setCurrentType(medicineType);
        form.setFieldsValue({ Name: medicineType.Name });
        setIsModalVisible(true);
    };

    // Delete the medicine type
    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this medicine type?')) {
            try {
                setLoading(true); // Start loading when deleting
                const response = await axios.delete(`${url}/api/medicine-types/${id}`);
                
                if (response.status === 200 || response.status === 204) {
                    // If deletion is successful, remove the deleted item from state
                    setMedicineTypes(prev => prev.filter(item => item.Id !== id));
                    notification.success({ message: 'Medicine type deleted successfully' });
                } else {
                    throw new Error('Failed to delete the medicine type.');
                }

            } catch (error) {
                console.error('Error deleting medicine type:', error);
                notification.error({ message: error.response?.data?.message || 'Error deleting medicine type' });
            } finally {
                setLoading(false); // Stop loading after completion
            }
        }
    };

    // Add or Update Medicine Type
    const handleSubmit = async (values) => {
        try {
            setLoading(true); // Start loading when submitting
    
            if (currentType) {
                // Update existing medicine type
                const response = await axios.put(`${url}/api/medicine-types/${currentType.Id}`, values);

                if (response.status === 200) {
                 
                    setMedicineTypes(medicineTypes.map(item =>
                        item.Id === currentType.Id ? { ...item, ...values } : item
                    ));
                    notification.success({ message: 'Medicine type updated successfully' });
                } else {
                    console.log("response create error", response.data)
                    // throw new Error('Failed to update the medicine type.');
                    console.log("error")
                }
            } else {
                // Add new medicine type
                const response = await axios.post(`${url}/api/medicine-types`, values);
                if (response.status === 200) {
                    console.log("response create xxxx", response.data.data)
                    // Check if response has valid data structure
                    const newType = response.data?.data; // Adjust this if the API structure is different
                    if (newType) {
                        setMedicineTypes(prev => [newType, ...prev]);
                        notification.success({ message: 'Medicine type added successfully' });
                    } else {
                        throw new Error('Failed to retrieve the added medicine type.');
                    }
                } else {
                    throw new Error('Failed to add the new medicine type.');
                }
            }

            form.resetFields(); // Reset the form fields
    
        } catch (error) {
            console.error('Error saving medicine type:', error);
            notification.error({ message: error.response?.data?.message || 'Error saving medicine type' });
        } finally {
            setLoading(false); // Stop loading after completion
            setIsModalVisible(false); // Close modal after everything is updated
        }
    };

    // console.log("medicineTypes", medicineTypes)

    // Table Columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <>
                    <Button type="primary" onClick={() => handleEdit(record)}>Edit</Button>
                    <Button type="danger" onClick={() => handleDelete(record.Id)} style={{ marginLeft: 8 }}>Delete</Button>
                </>
            ),
        },
    ];

    return (
        <div className="container mt-4">
            <h2>Manage Medicine Types</h2>
            <Button type="primary" onClick={handleAdd} style={{ marginBottom: 16 }}>
                Add New Medicine Type
            </Button>
            <Table
                dataSource={medicineTypes}
                columns={columns}
                rowKey="Id"
                loading={loading} // Show loading spinner when fetching data
                pagination={{ pageSize: 5 }}
            />

            <Modal
                title={currentType ? "Edit Medicine Type" : "Add New Medicine Type"}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        label="Medicine Type"
                        name="Name"
                        rules={[{ required: true, message: 'Please input the medicine type name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {currentType ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default MedicineTypeList;
